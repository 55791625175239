/*
 * 업무구분: 영업
 * 화 면 명: MSPFS309M
 * 화면설명: 매입신청2005
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <ur-page-container class="fts" :show-title="false" title="매입신청" :topButton="true">
    <!-- header start -->
    <fs-header
      ref="fsHeader"
      :propObj="pHeaderObj"
    ></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start" >
        <div class="input-top">
          <div class="column gap10">  
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis">매입계좌번호</label>
                <mo-decimal-field  
                    numeric
                  class="w130 input-account" 
                  ref="ed_accn_no"
                  :class="lv_ed_accn_no.error ? 'error' : ''"
                  v-model="lv_ed_accn_no.value"                   
                  @keyup="fn_acctSearch($event)"
                  @paste="fn_acctSearch($event, 'P')"
                  mask="#######-###"
                />
                <mo-dropdown 
                  class="w306"
                  ref="ed_acct_gb"
                  :items="lv_acct_list" 
                  v-model="lv_ed_acct_gb.value"
                  :disabled="lv_ed_acct_gb.disabled"
                  placeholder="선택" 
                  @input="fn_Acct_no_call"
                />
                <mo-dropdown 
                  class="w100"
                  :items="lv_commCode.code231" 
                  v-model="save_tp"
                  placeholder="선택"
                  disabled 
                />
              </div>

              <!-- "적합성 체크" 기능 삭제되어야 합니다! 2023-06-19 jy -->
              <!-- <div class="wrap-input row" v-if="lv_ed_acct_gb.value != null">
                <span class="row gap12">
                  {{fnd_grd_nm}}
                  <mo-checkbox class="checkBtn" @click="fn_fnd_adj_onclick" :checked="fnd_adj_stat_cd != ''" ref="btn_inq_fnd_adj">적합성체크</mo-checkbox>
                  <em v-if="fnd_adj_stat_cd == 'N'">미체크</em> 
                  <em :class="StaticFndAdjStatNm_style" v-if="fnd_adj_stat_cd == 'S'">{{StaticFndAdjStatNm}}</em> -->
                  <!-- <em class="colorC" v-if="fnd_adj_stat_cd == 'X'">투자성향평가 없음</em>
                  <em class="colorD" v-if="fnd_adj_stat_cd == 'E'">투자성향평가 2년경과</em>
                  <em class="colorR" v-if="fnd_adj_stat_cd == 'F'">부적합</em>
                  <em class="colorE" v-if="fnd_adj_stat_cd == 'P'">투자성향평가 미대상</em> -->
                <!-- </span>
              </div> -->
              <!-- "적합성 체크" 기능 삭제되어야 합니다! 2023-06-19 jy -->
              
            </div>
            <div class="row w100p gap40">
              <div class="wrap-input row">
                <label class="emphasis"> 출금구분 </label>
                <mo-radio-wrapper :items="rdo_draw_gb" v-model="draw_gb" 
                @input="div_search_rdo_draw_gb_onitemchanged($event)" class="row"
                />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 유선구분 </label>
                <mo-radio-wrapper :items="rdo_ars_gb" v-model="ars_gb" class="row" disabled/>
              </div> 
              <div class="wrap-input row">
                <label class="emphasis"> 대리인여부 </label>
                <mo-radio-wrapper :items="rdo_agnt_chk_yn" v-model="agnt_chk_yn.value" 
                @input="fn_agntSelect" class="row"
                />
              </div>
              <div class="wrap-button row flex1 justify-end">
                <mo-button @click="fn_init"> 초기화 </mo-button>
                
              </div>  
            </div>
          </div>
        </div>
      </ur-box-container>

      <!-- fs-cust-info start -->
      <fs-cust-info
        ref="fsCustInfo"
        :propObj="pCustInfoObj"
        @fs-cust-name="fn_SetEdCustName"
      ></fs-cust-info>
      <!-- fs-cust-info end -->

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> 브릿지(예수금)출금 </h2>
          <h3 class="table-title desc"> 출금계좌 </h3>  
        </div>
        <div class="wrap-table">
          <table class="table col-type type02">
            <!-- <colgroup>
              <col width="20%">
              <col width="">
              <col width="12%">
              <col width="">
              <col width="12%">
              <col width="">
            </colgroup> -->
            <tbody>
              <tr>
                <th class="w20p">
                  <span> 브릿지(예수금)계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="input-long" 
                      v-model="depo_acct_no.value" 
                      ref="depo_acct_no"
                      mask="#######-###-###"
                      readonly
                    />
                  </div>
                </td>
                <th class="w12p">
                  <span> 예수금잔액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="depo_reamt.value" :disabled="depo_reamt.disabled" readonly/>
                  </div>
                </td>
                <th class="w12p">
                  <span class="emphasis"> 비밀번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="password"
                      class="input-long"
                      v-model="passwd.value"
                      type="numberMax4"
                      dialog="Y"
                      start="0" 
                      end="-1"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"         
                    />
                    <mo-text-field 
                      v-else
                      type="password" 
                      class="input-long" 
                      v-model="passwd.value" 
                      :disabled="lv_acct_list.length == 0 || draw_gb == '1'" 
                      @keyup="fn_passwd_chk(1)"
                      maxlength="4"
                    />
                    <mo-button 
                      @click="fn_passwd_chk_onclick" 
                      :disabled="lv_acct_list.length == 0 || draw_gb == '1'" 
                      v-if="draw_gb === '0'"
                    > 확인 </mo-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>   
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title mt16">
          <h3 class="table-title desc"> 매입신청사항 </h3>  
        </div>
        <div class="wrap-table">
          <table class="table col-type type02 col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 신청금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field  
                    numeric
                      class="input-long" 
                      mask="number" 
                      v-model="req_amt.value" 
                      :disabled="req_amt.disabled" 
                      ref="req_amt"
                      @input="fn_req_amt_onchanged"
                    />
                  </div>
                </td>
                <th>
                  <span> 매입계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="input-long" 
                      v-model="recp_acct_no.value" 
                      mask="#######-###-###" 
                      readonly
                      />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>   
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title"> MMF당일환매출금 </h2>
          <h3 class="table-title desc"> 출금계좌 </h3>  
        </div>
        <div class="wrap-table">
          <table class="table col-type type02">
            <!-- <colgroup>
              <col width="20%">
              <col />
              <col width="12%">
              <col />
            </colgroup> -->
            <tbody>
              <tr>
                <th class="w20p">
                  <span class="emphasis"> 당일환매용MMF계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field  
                    numeric
                      class="w130 input-account" 
                      ref="ed_accn_no2"
                      :class="lv_ed_accn_no2.error ? 'error' : ''"
                      v-model="lv_ed_accn_no2.value" 
                      @keyup="fn_acctSearch($event, '', 2)"
                      @paste="fn_acctSearch($event, 'P', 2)"
                      :disabled="lv_ed_accn_no2.disabled"
                      mask="#######-###" 
                    /> 
                    <mo-dropdown 
                      class="w225" 
                      ref="ed_acct_gb2" 
                      :items="lv_acct_list2" 
                      v-model="lv_ed_acct_gb2.value" 
                      :disabled="lv_ed_acct_gb2.disabled" 
                      :key="acctGb2rRndrKey"
                      placeholder="선택하세요" 
                      @input="fn_Acct_no_call_forMMF"
                    />
                    <mo-text-field 
                      class="w80" 
                      v-model="lv_ed_cust_nm2.value"
                      disabled />
                  </div>
                </td>
                <th class="w12p">
                  <span class="emphasis"> 비밀번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <m-trans-key-input
                      v-if="isMobile && isMtrans"
                      ref="password2"
                      class="input-long"
                      v-model="passwd2.value"
                      type="numberMax4"
                      dialog="Y"
                      start="0" 
                      end="-1"
                      :isClear="lv_isClear2"
                      @masked-txt="fn_SetMaskedTxt2"         
                    />
                    <mo-text-field 
                      v-else
                      type="password" 
                      class="input-long" 
                      v-model="passwd2.value" 
                      :disabled="lv_acct_list2.length == 0 || draw_gb == '0'" 
                      @keyup="fn_passwd_chk(2)" 
                      maxlength="4"
                    />
                    <mo-button 
                      @click="fn_passwd_chk_onclick" 
                      :disabled="lv_acct_list2.length == 0 || draw_gb == '0'" 
                      v-if="draw_gb === '1'"
                      > 확인 </mo-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>   
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="row gap20 align-start w100p">
          <div class="left w50p column">
            <div class="wrap-table-title mt16">
              <h3 class="table-title desc"> 당일환매출금사항 </h3>  
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col4060">
                <!-- <colgroup>
                  <col width="40%">
                  <col width="60%">
                </colgroup> -->
                <tbody>
                  <tr>
                    <th>
                      <span class="emphasis"> 환매구분 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-dropdown 
                          :items="lv_commCode.code322" 
                          v-model="redem_gb.value" 
                          placeholder="선택하세요" 
                          :disabled="redem_gb.disabled"
                          @input="fn_cbo_redem_gb_onitemchanged" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="emphasis"> 금액(좌수) </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                         <mo-decimal-field  
                          numeric
                          class="input-long" 
                          mask="number" 
                          v-model="paym_amt.value" 
                          :disabled="paym_amt.disabled" 
                        />
                         <mo-button @click="fn_btn_redem_chk_onclick" :disabled="btn_redem_chk"> 확인 </mo-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="right w50p column">
            <div class="wrap-table-title mt16">
              <h3 class="table-title desc"> 인출가능금액 </h3>  
            </div>
            <div class="wrap-table">
              <table class="table col-type type02 col4060">
                <!-- <colgroup>
                  <col width="40%">
                  <col width="60%">
                </colgroup> -->
                <tbody>
                  <tr>
                    <th>
                      <span> 전액 출금가능금액 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field 
                          class="input-long" 
                          mask="number" 
                          v-model="paym_possamt.value" 
                          :disabled="paym_possamt.disabled" 
                          readonly
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 전액 출금가능좌수 </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field 
                          class="input-long" 
                          mask="number" 
                          v-model="paym_possqty.value" 
                          :disabled="paym_possqty.disabled"
                          readonly
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span> 출금신청금액(좌수) </span>
                    </th>
                    <td>
                      <div class="wrap-input row">
                        <mo-text-field 
                          class="input-long" 
                          mask="number" 
                          v-model="part_paym_possamt.value" 
                          :disabled="part_paym_possamt.disabled" 
                          readonly
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>  
        </div>    
		  </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title mt16">
          <h3 class="table-title desc"> 입금신청사항 </h3>  
        </div>
        <div class="wrap-table">
          <table class="table col-type type02 col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 입금신청금액(좌수) </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field  
                    numeric
                      class="input-long" 
                      mask="number" 
                      v-model="depo_req_amt.value" 
                      :disabled="depo_req_amt.disabled" 
                      readonly
                    />
                  </div>
                </td>
                <th>
                  <span> 입금계좌번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field 
                      class="input-long" 
                      v-model="depo_acct_no_MMF.value" 
                      disabled
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>    
		  </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_confirm" :disabled="btn_confirm">확인</mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- popup313 -->
    <msp-fs-313p
      ref="popup313"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack313"      
      :popupObj="pPopup313Obj"
    ></msp-fs-313p>

    <!-- popup314 -->
    <msp-fs-314p
      ref="popup314"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack314"
      :popupObj="pPopup314Obj"
    ></msp-fs-314p>

    <!-- popup320 -->
    <msp-fs-320p
      ref="popup320"
      @fs-alert-popup="fn_AlertPopup"
      @fs-popup-callback="fn_Popup_CallBack320"
      :popupObj="pPopup320Obj"
    ></msp-fs-320p>
   

    <!-- alert modal popup -->
    <fs-alert-popup
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></fs-alert-popup>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup'  // Alert 팝업 (공통)
import FSHeader from '~/src/ui/fs/comm/FSHeader'          // header 영역 (공통)
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'

import MSPFS313P from '~/src/ui/fs/MSPFS313P'  // 직원번호(직원검색) 팝업
import MSPFS314P from '~/src/ui/fs/MSPFS314P'  // 직원번호(직원검색) 팝업
import MSPFS320P from '~/src/ui/fs/MSPFS320P'  // 책임자 승인 팝업 
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo'      // 고객정보 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS309M",
  screenId: "MSPFS309M",
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-313p':MSPFS313P,
    'msp-fs-314p':MSPFS314P,
    'msp-fs-320p':MSPFS320P,
    'fs-cust-info': FSCustInfo,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
    this.lv_serverType = process.env.NODE_ENV
  },
  mounted() {
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.fn_init()
    this.param = this.$route.params
    console.log('param =>', this.param);
    // this.bfcer_cust_no = this.param.bfcer_cust_no
    // this.bfcer_accn_no = this.param.bfcer_accn_no
    
    
    //this.rname_no = param.rname_no
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      // 공통 객체
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,

      lv_isClear: false,            // 보안키패드 초기화
      lv_isClear2: false,            // 보안키패드 초기화
      
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      pHeaderObj: {
        title: '매입신청',
        step: '',
      },
      lv_commCode : {}, //공통코드
      pAlertPopupObj: {},
      pPopup313Obj: {},
      pPopup314Obj: {},
      pPopup320Obj: {},

      pCustInfoObj : {}, //고객정보
      // 검색조건
      lv_ed_accn_no: {
        value : '',
        disabled : true
      }, //매입계좌번호 
      lv_ed_acct_gb: {
        value : '',
        disabled : true
      }, //계좌종류
      lv_ed_cust_nm: {}, // 고객명
      // 계좌정보
      lv_cust_info: {},  // 고객정보
      lv_acct_list: [],  // 계좌조회목록

      ds_acct_no : [], // 계좌정보 
      ds_acct_no2 : [], //MMF 계좌정보 

      save_tp         : '',  //매입계좌번호3 (임의식..)  

      //  2023.06 적합성 체크 기능 삭제 요청 
      // fnd_adj_stat_cd : '', //적합부적합평가

      //MMF 계좌조회시 param 
      redem_acct_no_param : {
        input_check        : '81',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn          : 'N',
        bridge_aply_cd     : ''
      },
      // 계좌조회시 param
      acct_no_param : {
        input_check        : 'C',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn          : 'N',
        bridge_aply_cd     : ''
      },

      fnd_grd_nm  : '',   //매입계좌번호 - 매우낮은 어쩌고 
      draw_gb     : '',      //출금구분값 
      ars_gb      : 'N',      //유선구분값 -> 일반 고정 유선 비활성 : 2 28
      agnt_chk_yn : {
        value : 'N'
      }, //대리인여부

      //브릿지(예수금)출금
      depo_acct_no      : {
        value : '',
        disabled : true
      }, //브릿지(예수금)계좌번호
      depo_reamt        : {
        value : '',
        disabled : true
      }, //예수금잔액
      passwd            : {
        value : '',
        disabled : true
      },//비밀번호 

      req_amt      : {
        value : '',
        disabled : true
      }, // 신청금액
      recp_acct_no : {
        value : '',
        disabled : true
      }, //매입계좌번호 

      //MMF당일환매출금 
      lv_ed_accn_no2     : {
        value : '',
        disabled : true
      }, //매입계좌번호 
      lv_ed_acct_gb2     : {
        value : '',
        disabled : true
      }, //계좌종류
      lv_ed_cust_nm2     : {
        value : '',
        disabled : true
      }, // 고객명
      lv_cust_info2      : {},  // 고객정보
      lv_acct_list2      : [],  // 계좌조회목록
      passwd2            : {
        value : '',
        disabled : true
      },       //비밀번호

      redem_gb      : {
        value : '',
        disabled : true
      }, //환매구분
      paym_amt      : {
        value : '',
        disabled : true
      }, //금액(좌수)
      btn_redem_chk : true, //확인버튼 활성화,비활성화 여부 

      paym_possamt      : {
        value : '',
        disabled : true
      }, //전액 출금가능금액
      paym_possqty      : {
        value : '',
        disabled : true
      }, //전액 출금가능좌수
      part_paym_possamt : {
        value : '',
        disabled : true
      }, //출금신청금액(좌수)

      depo_req_amt     : {
        value : '',
        disabled : true
      }, //입금신청금액(좌수)
      depo_acct_no_MMF : {
        value : '',
        disabled : true
      }, //입금계좌번호

      ds_ivt_fnd_adj_info : [], //투자성향 2년경과 여부 조회 결과 

      StaticFndAdjStatNm_style : '', //적합성 체크 결과 text color
      StaticFndAdjStatNm       : '', //적합성 체크 결과 text

      ds_rp251000_i_01Vo : {}, //인출가능금액호출시 필요한 dataset 

      select_yn : 'N', // 조회 체크 

      ds_bridge_req : [], // 브릿지 출금  ds_rp200500_i_00vo
      ds_mmf_redem_req : [], //MMF당일 출금 req // ds_rp201500_i_00vo
      ds_mmf_redem : [], //ds_rp201500_o_00vo : {}, MMF당일 출금 결과 ds

      ds_report : {}, // 전자서식 파라미터 저장 
      //------------------------------------------------------------------------------------
      param : {},
      staf_eno : this.getStore('userInfo').getters.getUserInfo.userId,

      tempMMF : '', // MMF로 변경시 입금계좌번호 임시 저장할 변수 
      lv_serverType : process.env.NODE_ENV,

      btn_confirm : false, // 확인버튼 비활성화 여부
      acctGb2rRndrKey : 0,

      lv_bfcer_apamt_accn_no : '', // 브릿지(예수금)계좌번호 임시 저장

      gv_adm_seq_no : '', // 책임자 승인 정보 
      gv_adm_eno : 0, 
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    //출금구분
    rdo_draw_gb() {
      let rtn = [];
      rtn.push({value: '0', text: '브릿지(예수금)계좌송금'});
      rtn.push({value: '1', text: 'MMF당일환매송금'});
      return rtn;
    }, 
    //유선구분
    rdo_ars_gb() {
      let rtn = [];
      rtn.push({value: 'N', text: '일반'});
      rtn.push({value: 'Y', text: '유선'});
      return rtn;
    }, 
    //대리인여부
    rdo_agnt_chk_yn() {
      let rtn = [];
      rtn.push({value: 'Y', text: '예'});
      rtn.push({value: 'N', text: '아니오'});
      return rtn;
    }, 
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 출금구분 선택
    // 'draw_gb': {
    //   handler(after, before) {
    //     if(after == before){
    //       return;
    //     }else{
    //       this.div_search_rdo_draw_gb_onitemchanged(after);
    //     }
    //   },
    //   deep: true
    // },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /******************************************************************************
    * Function명 : fn_SetMaskedTxt
    * 설명       : 보안키패드 체크
    ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if(FSCommUtil.gfn_length(this.lv_masked_val) >= 4) {
        // this.fn_SearchList()
        this.fn_passwd_chk(1)
      }
    },

    fn_SetMaskedTxt2(val) {
      this.lv_masked_val2 = val
      if(FSCommUtil.gfn_length(this.lv_masked_val2) >= 4) {
        // this.fn_SearchList()
        this.fn_passwd_chk(2)
      }
    },
    
    /******************************************************************************
     * Function명 : fn_init
     * 설명       : 초기화
     ******************************************************************************/
    fn_init (){
      console.log('초기화 lv_basInfo ======>', this.lv_basInfo);
       // 검색조건
      this.lv_ed_accn_no            = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no');      // 계좌번호
      this.lv_ed_acct_gb            = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb');       // 계좌종류
      this.lv_ed_cust_nm            = FSCommUtil.gfn_dataSet(1);                                   // 고객명
      this.lv_ed_cust_nm2           = FSCommUtil.gfn_dataSet(1); 
      // 2023.06 적합성 체크 기능 삭제 요청 
      // this.fnd_adj_stat_cd = ''; // 적합성 체크 값

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init();

      // 대리인
      this.agnt_chk_yn.value = 'N';

      this.lv_acct_list = [];
      this.lv_acct_list2 = [];

      // 브릿지 input 
      this.depo_acct_no       = FSCommUtil.gfn_dataSet(1, '', false, '', 'depo_acct_no');      // 계좌번호
      this.passwd             = FSCommUtil.gfn_dataSet(1, '', false, '', 'password') 
      this.req_amt.value      = "";
      this.recp_acct_no.value = "";

      // MMF input 
      this.lv_ed_accn_no2.value    = '';
      this.lv_ed_acct_gb2.value    = '';
      this.passwd2                 = FSCommUtil.gfn_dataSet(1, '', false, '', 'password2') 
      this.redem_gb.value          = '';
      this.paym_amt.value          = '';
      this.paym_possamt.value      = '';
      this.paym_possqty.value      = '';
      this.part_paym_possamt.value = '';
      this.depo_req_amt.value      = '';
      this.depo_acct_no_MMF.value  = '';

      // 브릿지 활성화 
      this.depo_acct_no.disabled = false;
      this.depo_reamt.disabled   = false;
      this.depo_reamt.value = '0';
      this.passwd.disabled = false;
      this.req_amt.disabled = true; 
      this.recp_acct_no.disabled = false;

      //MMF비활성화 
      this.lv_ed_accn_no2.disabled = true;
      this.lv_ed_acct_gb2.disabled = true;
      this.passwd2.disabled = true;
      this.redem_gb.disabled = true;
      this.paym_amt.disabled = true;
      this.btn_redem_chk = true;
      this.paym_possamt.disabled = true;
      this.paym_possqty.disabled = true;
      this.part_paym_possamt.disabled = true;
      this.depo_req_amt.disabled = true;
      this.depo_acct_no_MMF.disabled = true;

      console.log('파라미터', this.param); 
      console.log('사용자정보', this.staf_eno);

      this.acct_no_param = {
        input_check : 'C',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn : 'N',
        bridge_aply_cd : ''
      }

      
      this.draw_gb ="0"; //출금구분 

      this.redem_acct_no_param = {
        input_check        : '81',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn          : 'N',
        bridge_aply_cd     : ''
      }

      //보안키패드 관련
      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_isClear2 = !this.lv_isClear2 ? true : false
      this.lv_masked_val = ''
      this.lv_masked_val2 = ''
      // this.fn_setDivisionComp("0");
      // this.$refs['ed_accn_no'].focus()

      // 2023.06 펀드적합성 삭제 요청
      // this.fn_setFndRiskDispSet("","");			// 펀드위험등급 Clear
      // this.fn_setFndAdjProcStatDispSet( "C" );	// 펀드적합버튼/상태 Clear		  

    },

    /******************************************************************************
     * Function명 : fn_Acct_no_call
     * 설명       : 계좌 입력, 변경 시 
     ******************************************************************************/
    fn_Acct_no_call(){
      console.log('fn_Acct_no_call 계좌 입력 변경시 --->', this.ds_acct_no);
      this.btn_confirm = false;
      // MMF 비밀번호 확인버튼 hide 
      // 2023-07-21 Jira PMPM22000016-1015 계좌 변경 시 비밀번호 확인버튼 사라지지 않게 하기 => 아래 주석처리
      // this.btn_passwd2_showyn = false; 
      
      this.req_amt.value = ""
		  this.depo_reamt.value = "0";
      this.save_tp = "";

      // 2023.06 펀드적합성 삭제 요청
      // this.fn_setFndAdjProcStatDispSet( "N" );	// 펀드적합성체크 : 미체크

      var objDs = this.ds_acct_no[this.lv_ed_acct_gb.value]
      console.log('@@@@@@@', objDs)
      console.log('@@@@@@@', this.lv_ed_acct_gb.value)
      console.log('@@@@@@@', this.ds_acct_no)
      // var fnd_grd_cd = objDs.fnd_grd_cd;
      // var fnd_grd_nm = objDs.fnd_grd_nm;
      
      // 2023.06 펀드적합성 삭제 요청
      // this.fn_setFndRiskDispSet(fnd_grd_cd, fnd_grd_nm);	// 펀드 위험등급 표시

      this.save_tp = objDs.bfcer_sav_typ_cd;

      var sRecpAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue
      //브릿지계좌
      if(this.draw_gb == "0") {
        //예수금잔액
        this.depo_reamt.value = objDs.depo_paym_poss_amt
        //매입계좌
        this.recp_acct_no.value = sRecpAcctNo
        //브릿지계좌( lv_bfcer_apamt_accn_no => 출금구분 선택 시 브릿지 계좌번호 값이 날라가는 것을 방지하기 위해 임시로 저장)
        this.depo_acct_no.value     = objDs.bfcer_apamt_accn_no
        console.log('확인@@@@2',this.depo_acct_no.value)
        this.lv_bfcer_apamt_accn_no = objDs.bfcer_apamt_accn_no
        //입력사항
        this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(sRecpAcctNo)

        this.tempMMF = objDs.bfcer_apamt_accn_no

      } else if (this.draw_gb == "1") {
        this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(objDs.bfcer_apamt_accn_no)
      }
      
    },

    /******************************************************************************
     * Function명 : fn_Acct_no_call_forMMF
     * 설명       : MMF 계좌 입력, 변경 시 
     ******************************************************************************/
    fn_Acct_no_call_forMMF(){
      this.acctGb2rRndrKey++
      console.log('fn_Acct_no_call_forMMF 계좌 입력 변경시 --->')

      // 2023-07-21 Jira PMPM22000016-1015 계좌 변경 시 비밀번호 확인버튼 사라지지 않게 하기 => 아래 주석처리
      // this.btn_passwd2_showyn = false; 

      var objDs = this.ds_acct_no

      if (objDs.length > 0) {
        var nRow = this.lv_ed_acct_gb.value
        var sDepoAcctNo = objDs[nRow].bfcer_apamt_accn_no

        if (this.draw_gb == "1"){
          this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(sDepoAcctNo)
        } 
      }

      //MMF 항목 활성화 
      this.passwd2.value = ""
      
      this.lv_isClear2 = !this.lv_isClear2 ? true : false
      this.lv_masked_val2 = ""

      this.redem_gb.value = ""
      this.redem_gb.disabled = true

      //당일환매출금사항 초기화(전액으로)
      this.paym_amt.value = ""
      this.paym_amt.disabled = true
    },

    /**************************************************************************************************************
    * Function명 : fn_passwd_chk_onclick
    * 설명       : 비밀번호 확인버튼
    ***************************************************************************************************************/
    fn_passwd_chk_onclick(){
      
      console.log('비밀번호 확인버튼 클릭 이벤트')

      //브릿지 계좌 
      if(this.draw_gb == "0"){

        console.log('브릿지계좌 비밀번호 입력');
        //예수금계좌 비밀번호 입력 완료시 매입가능금액 조회
        this.fn_getRedemPossamt_bridge("S");


        if(!(this.isMobile && this.isMtrans)){
          if(this.passwd.value == '' || FSCommUtil.gfn_length(this.passwd.value) < 4){
            FSCommUtil.gfn_validate(this, "비밀번호 입력오류입니다.") 
            return;
          }
        }else{
          if(this.lv_masked_val == '' || FSCommUtil.gfn_length(this.lv_masked_val) < 4){
            FSCommUtil.gfn_validate(this, "비밀번호 입력오류입니다.") 
            return;
          }
        }
        
        console.log('비밀번호1 확인버튼 값 확인 ', this.draw_gb);

        this.req_amt.disabled = false;//매입신청금액
      }

      //MMF
      else{
        console.log('MMF계좌 비밀번호 입력');
        this.redem_gb.disabled = false;
        if (!this.fn_paymSearchValidate()){
          console.log('fn_paymSearchValidate == false')
          return;
        } 
        this.select_yn = "Y";
        this.fn_getRedemPossamt("S");
      }
    },

    /************************************************************************************************
     * Function명 : fn_getRedemPossamt_bridge
     * 설명       : 예수금계좌 비밀번호 입력 완료시 매입가능금액 조회
     ************************************************************************************************/
    fn_getRedemPossamt_bridge(e){
      console.log("fn_getRedemPossamt_bridge 시작" ,e);
      //인출가능금액 호출전 값 셋팅 함수 호출 
      this.fn_prepareSearchPossAmt("S");//sInpDiv
      console.log('조회1 dsrp2510', this.ds_rp251000_i_01Vo);
      // this.ds_rp251000_i_01Vo = {};
      // console.log('조회1 dsrp2510 2222', this.ds_rp251000_i_01Vo);

      console.log("브릿지 fn_getRedemPossamt_bridge 통과");

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570212_S'
      this.eaiCommObj.params = {
        bfcer_accn_no      : this.ds_rp251000_i_01Vo.bfcer_accn_no,     // 수익증권계좌번호, Length : 13
        bfcer_rpus_apl_amt : "",                                        // 수익증권환매신청금액, Length : 25
        bfcer_rpus_sc_cd   : this.ds_rp251000_i_01Vo.bfcer_rpus_sc_cd,  // 수익증권환매구분코드, Length : 2
        callcenter_link_yn : this.ds_rp251000_i_01Vo.callcenter_link_yn,// 연결여부, Length : 1
        comp_cd            : this.ds_rp251000_i_01Vo.comp_cd,           // 회사코드, Length : 10
        ars_pswn_yn        : "",                                        // ARS 비밀번호 체크 여부
        proc_gb            : this.ds_rp251000_i_01Vo.proc_gb,           // 처리구분코드
        secure_encsymm     : "",                                        // 암호화 대칭키
        secure_passv       : this.passwd.value,                         // 암호화 비밀번호
        tr_code            : 'RP2005',                                  // 거래코드
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_getRedemPossamt_bridge_callback)
      //암호화key 서버 올림
      // var encALGO = "SEED";
      // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
      // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);

      console.log("fn_getRedemPossamt_bridge완료");
    },

    /************************************************************************************************
     * Function명 : fn_getRedemPossamt_bridge_callback
     * 설명       : 인출가능금액조회 후 처리
     ************************************************************************************************/
    fn_getRedemPossamt_bridge_callback(response){
      console.log('인출가능금액조회 후 처리', response)
      

      if( response.data.error_msg == "809900" || response.data.error_msg == " "){

        if( this.draw_gb == "0" || this.draw_gb == 0 ){
          this.req_amt.disabled = false; //매입신청금액enable
          this.redem_gb.disabled = true; //환매구분 disabled 
        }
        
        this.redem_gb.value    = this.ds_rp251000_i_01Vo.bfcer_rpus_sc_cd;
        

        if ( this.redem_gb.value == '11' ){//전액환매
          //금액및 출금신청금액에 출금가능금액 셋팅
          this.paym_amt.value          = response.data.eval_amt;
          this.part_paym_possamt.value = response.data.paym_possamt;   

          this.paym_possamt.value = response.data.eval_amt; //전액출금가능금액
          this.paym_possqty.value = response.data.paym_possqty; //전액출금가능좌수
          this.depo_req_amt.value = response.data.paym_possamt; // 입금신청금액
        
        }else if (this.redem_gb.value == '12' ){//좌수환매
          this.part_paym_possamt.value = response.data.paym_possamt;  //출금신청금액
          this.depo_req_amt.value      = response.data.paym_possamt; // 입금신청금액 
          
        }else if (this.redem_gb.value == '13' ){//정액환매
          this.part_paym_possamt.value = response.data.paym_possamt;  //출금신청좌수
          this.depo_req_amt.value      = this.paym_amt.value; // 입금신청금액 
        }

        //최초조회시만
        if ( this.select_yn == "Y" ){
          console.log("빈값선택하기");	
          this.redem_gb.value = '';	//빈값선택하기	
        }	       
        this.req_amt.disabled = false; //신청금액 활성화

      }else if ( response.data.error_msg == '809990' ) {
          let t_popupObj = {content: "조회 결과가 없습니다."}
          this.fn_AlertPopup(0, t_popupObj)

      } else {
          let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
          this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /************************************************************************************************
     * Function명 : fn_getRedemPossamt
     * 설명       : 인출가능금액 호출
     ************************************************************************************************/
    fn_getRedemPossamt(sInpDiv){
      console.log("인출가능금액 호출");
      if (!this.fn_paymSearchValidate()){
        return;
      } 

      //MMF출금계좌
      var sRedemAcctNo = this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue;
      //매수계좌
      var sAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;

      console.log("인출가능금액 호출전 체크2");

      if (String(sRedemAcctNo).substr(0, 7) != String(sAcctNo).substr(0, 7)) {
        let t_popupObj = {content : "입금하실 계좌의 예금주와 출금하실 계좌 예금주가 같아야합니다."}
        this.fn_AlertPopup(0, t_popupObj) 

        // this.redem_acct_no.fn_init();
        // this.redem_acct_no.setFunctionCall("Y");
        // this.redem_acct_no.setInputCheck("81"); //당일출금펀드만 선택-2014.02.07 김진규 추가
        this.redem_acct_no_param = {
        input_check : '81',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn : 'N',
        bridge_aply_cd : ''
        }

        // if(!this.gfn_isNull(sGrpNo)){
        //   this.redem_acct_no.value = sGrpNo;
        // }

        this.passwd2.value = ''
        this.lv_masked_val2 = ""

        return;
      }
      console.log("인출가능금액 호출전 체크3");

      this.fn_prepareSearchPossAmt(sInpDiv);

      if(sInpDiv == "S"){
        //환매구분 
        this.redem_gb.value = ''; 
        // this.redem_gb.disabled = true; 
        //금액(좌수)
        this.paym_amt.value = '';
        this.paym_amt.disabled = true;
        //확인버튼
        this.btn_redem_chk = true;
        //전액출금가능금액
        this.paym_possamt.value = '';
        this.paym_possqty.value = '';
      }
      console.log("인출가능금액 호출전 체크4");    
      
      this.depo_req_amt.value = "";
      //출금신청금액(좌수)
      this.part_paym_possamt.value = "";

      //서버에 호출 할 정보를 셋팅해준다.
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570212_S'
      this.eaiCommObj.params = {
        bfcer_accn_no      : this.ds_rp251000_i_01Vo.bfcer_accn_no, // 수익증권계좌번호, Length : 13
        bfcer_rpus_apl_amt : (sInpDiv == "S0") ? this.paym_amt.value : '',  // 수익증권환매신청금액, Length : 25
        bfcer_rpus_sc_cd   : this.ds_rp251000_i_01Vo.bfcer_rpus_sc_cd, // 수익증권환매구분코드, Length : 2
        callcenter_link_yn : this.ds_rp251000_i_01Vo.callcenter_link_yn, // 연결여부, Length : 1
        comp_cd            : this.ds_rp251000_i_01Vo.comp_cd, // 회사코드, Length : 10
        ars_pswn_yn        : "", // ARS 비밀번호 체크 여부
        proc_gb            : this.ds_rp251000_i_01Vo.proc_gb, // 처리구분코드
        secure_encsymm     : "", // 암호화 대칭키
        secure_passv       : this.passwd2.value, // 암호화 비밀번호
        tr_code            : 'RP2005', // 거래코드
      }
  
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_getRedemPossamt_bridge_callback)
      //암호화key 서버 올림
      // var encALGO = "SEED";
      // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
      // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);

    },
    
    /************************************************************************************************
     * Function명 : fn_paymSearchValidate
     * 설명       : MMF 인출가능금액 호출
     ************************************************************************************************/
    fn_paymSearchValidate(){
      console.log("인출가능금액 호출전 체크");
      var bChkRtn = true
      var sAcctNo = this.lv_ed_accn_no2.value
      var sPwd    = (this.isMobile && this.isMtrans) ? this.lv_masked_val2 : this.passwd2.value
      var sRedemGb = this.redem_gb.value
      var sPaymAmt = this.paym_amt.value
      
      //계좌번호가 입력되지 않았을 경우 
      if(FSCommUtil.gfn_isNull(sAcctNo)){
        bChkRtn = false
      }
      //비밀번호가 입력되지 않았을 경우 
      if(FSCommUtil.gfn_isNull(sPwd)){
        bChkRtn = false
      }
      // 환매구분 12 && 금액(좌수)
      if(sRedemGb == "12" && FSCommUtil.gfn_isNull(sPaymAmt)){ 
        let t_popupObj = {content : "출금할 금액(좌수)을 입력하신후 확인버튼를 누르세요."}
        this.fn_AlertPopup(0, t_popupObj) 
        bChkRtn = false
      }
      return bChkRtn;
    },

    /************************************************************************************************
     * Function명 : fn_paymSearchValidate
     * 설명       : 인출가능금액 호출전 값 셋팅
     ************************************************************************************************/
    fn_prepareSearchPossAmt(sInpDiv){
      console.log("fn_prepareSearchPossAmt 인출가능금액 호출전1111", sInpDiv)

      if(FSCommUtil.gfn_isNull(sInpDiv)){
        sInpDiv = "S"
      }
      console.log(" fn_prepareSearchPossAmt 인출가능금액 호출전2222", sInpDiv)
      var sBfcerAccnNo = ""
      var sBfcerRpusAplAmt = ""
      var sBfcerRpusScCd = ""
        
      if (this.draw_gb == "0"){
        //브릿지계좌
        sBfcerAccnNo      = this.depo_acct_no.value
        sBfcerRpusAplAmt  = ""//this.div_detail.mae_paym_amt.value;
        console.log("브릿지계좌 인출가능금액 호출전3333", this.depo_acct_no.value)
      }   
      else {
        //MMF계좌
        sBfcerAccnNo     = this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue
        sBfcerRpusScCd   = this.redem_gb.value
        sBfcerRpusAplAmt = this.paym_amt.value
      }  
      console.log("인출가능금액 호출전4444")


      this.ds_rp251000_i_01Vo = {
        bfcer_accn_no      : sBfcerAccnNo, // 수익증권계좌번호, Length : 13
        bfcer_rpus_apl_amt : sBfcerRpusAplAmt,  // 수익증권환매신청금액, Length : 25
        bfcer_rpus_sc_cd   : sInpDiv == "S" ? "11" : sBfcerRpusScCd, // 수익증권환매구분코드, Length : 2
        callcenter_link_yn : "N", // 연결여부, Length : 1
        comp_cd            : "00001", // 회사코드, Length : 10
        proc_gb            : sInpDiv,
      };
      console.log("인출가능금액 호출전5555") 	
      
    },

    /**************************************************************************************************************
    * Function명 : div_search_rdo_draw_gb_onitemchanged
    * 설명       : 출금구분 선택시
    ***************************************************************************************************************/
    div_search_rdo_draw_gb_onitemchanged(){
      console.log('div_search_rdo_draw_gb_onitemchanged')
      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_isClear2 = !this.lv_isClear2 ? true : false
      this.lv_masked_val = ''
      this.lv_masked_val2 = ''
      this.passwd             = FSCommUtil.gfn_dataSet(1, '', false, '', 'password') 
      this.passwd2                 = FSCommUtil.gfn_dataSet(1, '', false, '', 'password2') 

      this.fn_setDivisionComp(this.draw_gb)
      // 20200107 bjm, 출금구분 브릿지 선택시 유선구분관련 체크하지 않도록 수정.
      if(this.draw_gb == "0"){
        this.req_amt.disabled = true
        this.redem_gb.disabled = true
        // MMF당일 환매출금계좌번호 초기화
        this.lv_ed_accn_no2.value = ''
        this.lv_ed_acct_gb2.value = ''
        this.lv_acct_list2 = []
        this.lv_ed_cust_nm2.value = ''
        this.passwd2.value = ''
        this.lv_masked_val2 = ''
        this.depo_acct_no.value = this.lv_bfcer_apamt_accn_no
      }else{//MMF환매경우
        this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(this.tempMMF)
      }

    },

    /**************************************************************************************************************
    * Function명 : fn_setDivisionComp
    * 설명       : 출금구분 선택시 초기화
    ***************************************************************************************************************/
    fn_setDivisionComp(sGb){
      console.log('fn_setDivisionComp', sGb)

      if(sGb == 0) {
        // 브릿지 활성화 
        this.depo_acct_no.disabled = false
        this.depo_reamt.disabled   = false
        this.passwd.disabled       = false
        this.recp_acct_no.disabled = false

        //MMF비활성화 
        this.lv_ed_accn_no2.disabled    = true
        this.lv_ed_acct_gb2.disabled    = true
        this.passwd2.disabled           = true
        this.redem_gb.disabled          = true
        this.paym_amt.disabled          = true
        this.btn_redem_chk              = true
        this.paym_possamt.disabled      = true
        this.paym_possqty.disabled      = true
        this.part_paym_possamt.disabled = true
        this.depo_req_amt.disabled      = true
        this.depo_acct_no_MMF.disabled  = true

        //당일환매출금사항 초기화
        this.paym_amt.value = "";

        //인출가능금액 초기화
        this.paym_possamt.value      = "" 
        this.paym_possqty.value      = ""
        this.part_paym_possamt.value = ""
        
        //브릿지계좌 재셋팅 //계좌 입력 전에는 위 항목까지만 세팅하고 나머지는 패스 
        if(this.lv_ed_accn_no.value == '' || FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)){
          return;
        }
        var nAcctRow        = this.lv_ed_acct_gb.value
        var objAcctDs       = this.ds_acct_no
        console.log('objAcctDs[nAcctRow].bfcer_apamt_accn_no', objAcctDs)
        console.log('objAcctDs[nAcctRow].bfcer_apamt_accn_no', objAcctDs[nAcctRow].bfcer_apamt_accn_no)
        this.depo_acct_no.value = this.ds_acct_no[this.lv_ed_acct_gb.value].bfcer_apamt_accn_no//objAcctDs[nAcctRow].bfcer_apamt_accn_no

        // 예수금잔액
        var sDepoPaymPossAmt = FSCommUtil.gfn_isNull(objAcctDs[nAcctRow].depo_paym_poss_amt) ? '' : objAcctDs[nAcctRow].depo_paym_poss_amt;

        console.log("예수금잔액:", sDepoPaymPossAmt)
        console.log("예수금잔액:", objAcctDs)

        if( FSCommUtil.gfn_isNull(sDepoPaymPossAmt) ){
          sDepoPaymPossAmt = 0
        }
        sDepoPaymPossAmt = Number(sDepoPaymPossAmt)

        // 매입계좌
        var sRecpAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;    // 계좌번호
        this.recp_acct_no.value = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;    // 계좌번호

        //입금신청사항
        this.depo_reamt.value = sDepoPaymPossAmt
      
        // 20200117 bjm 매입계좌번호 입력후 출금구분 변경시에는 초기화 하지 않음.
        if( !FSCommUtil.gfn_isNull(sRecpAcctNo) ){
          this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(sRecpAcctNo)
        }
            
        this.req_amt.value = ""
        //입금신청사항 초기화
        this.depo_req_amt.value = ""
        
        this.$refs['req_amt'].focus()

      //MMF 선택시 
      } else {
        // 브릿지 비활성화 
        this.depo_acct_no.disabled = true
        this.depo_reamt.disabled   = true
        this.passwd.disabled       = true
        this.req_amt.disabled      = true
        this.recp_acct_no.disabled = true

        //MMF 활성화 
        this.lv_ed_accn_no2.disabled = false
        this.lv_ed_acct_gb2.disabled = false
        this.passwd2.disabled        = false
        this.redem_gb.disabled       = true
        this.paym_amt.disabled       = true
        this.btn_redem_chk           = false
        this.paym_possamt.disabled   = false
        this.paym_possqty.disabled   = false

        this.part_paym_possamt.disabled = false
        this.depo_req_amt.disabled      = false
        this.depo_acct_no_MMF.disabled  = false

        //예수금 항목 비활성화
        this.depo_acct_no.value = "";
        this.depo_reamt.value   = ""

        //매입신청사항 초기화
        this.req_amt.value      = ""
        this.recp_acct_no.value = ""

        //MMF 항목 활성화
        this.passwd2.value = ""
        this.lv_masked_val2 = ""
        
        // this.redem_acct_no.value = "";
        this.redem_acct_no_param = {
        input_check        : '81',
        bfcer_accn_stat_cd : 'Y',
        int_tr_yn          : 'N',
        bridge_aply_cd     : ''
        }

        var sGrpNo = this.lv_ed_accn_no.value

        //고객번호만 MMF계좌에 입력
        if (!FSCommUtil.gfn_isNull(sGrpNo)) {
          this.lv_ed_accn_no2.value = String(sGrpNo).substr(0, 7)
        }

        //당일환매출금사항 초기화
        this.paym_amt.value = ""

        //인출가능금액 초기화
        this.paym_possamt.value = ""
        this.paym_possqty.value = ""
        this.part_paym_possamt.value = ""


        //입금신청사항 초기화
        this.depo_req_amt.value = ""
      }
    },

    /**************************************************************************************************************
    * Function명 : fn_passwd_chk
    * 설명       : 비밀번호 체크 
    ***************************************************************************************************************/
    fn_passwd_chk(value){
      if(!this.isMobile){

        if( value == 1 ){
          if(FSCommUtil.gfn_length(this.passwd.value) < 4){
            return;
          }
        }else{
          if( FSCommUtil.gfn_length(this.passwd2.value) < 4 ){
            return;
          }
        }

      }else{

        if( value == 1 ){
          if(FSCommUtil.gfn_length(this.lv_masked_val) < 4){
            return;
          }
        }else{
          if( FSCommUtil.gfn_length(this.lv_masked_val2) < 4 ){
            return;
          }
        }
        
      }
      
    },

    /**************************************************************************************************************
    * Function명 : fn_confirm
    * 설명       : 브릿지 - CONFIRM 확인버튼
    ***************************************************************************************************************/
    fn_confirm(){
      console.log("fn_confirm ==== value : ", this.agnt_chk_yn.value);    
    
      var sDrawGb = this.draw_gb;
      
      if (FSCommUtil.gfn_isNull(this.agnt_chk_yn.value)){
        let t_popupObj = {content: "대리인여부을 선택하십시오."}
        this.fn_AlertPopup(0, t_popupObj)
        return;
      }
        
      // 유효성검사
      if (sDrawGb == "0") {
          //브릿지계좌 선택
        if (!this.fn_validateConfirmAcct()){
          console.log('브릿지계좌 유효성 검사 실패')
          return;
        } 
      }else if(sDrawGb == "1"){
        //MMF계좌선택
        if(!this.fn_validateConfirmRedem()){
          return;
        }
      }

      console.log("확인버튼 선체크 완료 ", sDrawGb);
            
      //브릿지계좌
      if(sDrawGb == "0"){
        //책임자 승인 재호출시는 해당메시지 안띄우기 위해
        // //todo.. test
        // if(FSCommUtil.gfn_isNull(this.gv_adm_seq_no)){
        //   console.log("aaaa");
        //   let t_popupObj = {
        //     content: "매입신청을 하시겠습니까?"
        //   }
        //   this.fn_AlertPopup(0, t_popupObj)
        // }   
            
        //------------------fn_prepareConfirm start ----------------------------------

        console.log("sDrawGb : " + this.draw_gb + ", this.div_detail.mae_depo_acct_no.value : " + this.depo_acct_no.value);
        var sBuyAccnNo  = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue    // 계좌번호
        var sMmfDfraAccnNo = "";
        var sMmfDfraDlNo   = "";
        console.log('this.ds_mmf_redem ========>', this.ds_mmf_redem)
        if(!FSCommUtil.gfn_isNull(this.ds_mmf_redem)){
          if(!FSCommUtil.gfn_isNull(this.ds_mmf_redem.paym_cust_no)){                              
          sMmfDfraAccnNo = this.ds_mmf_redem.paym_cust_no + this.ds_mmf_redem.paym_bfcer_cstgr_no + String(this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue).substr(10, 3)
          sMmfDfraDlNo   = this.ds_mmf_redem.paym_tr_no
          }
        } else {
          sMmfDfraAccnNo = "";
          sMmfDfraDlNo   = "";
        }
        console.log('FSCommUtil.gfn_isEmpty(this.ds_mmf_redem)', FSCommUtil.gfn_isNull(this.ds_mmf_redem));
        console.log('sMmfDfraDlNo =====?', sMmfDfraDlNo);
        console.log("sBuyAccnNo : " + sBuyAccnNo);

        console.log("유선구분 " + this.ars_gb );
        this.ds_bridge_req = [];
        // this.ds_bridge_req.push({
        //   tr_code         : (this.draw_gb == "0")?"RP2005":"RP2015",
        //   proc_gb         : 
        //   media           : "I",	
        //   tel_use_yn      : this.ars_gb,
        //   dfra_accn_no    : this.ds_acct_no[0].bfcer_apamt_accn_no,
        //   buy_accn_no     : this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
        //   bfcer_cstgr_no  : String(sBuyAccnNo).substr(0, 10),
        //   acct_seq_no     : String(sBuyAccnNo).substr(10),
        //   buy_apl_amt     : (this.draw_gb == "0") ? this.req_amt.value : this.paym_amt.value,
        //   mmf_dfra_accn_no : sMmfDfraAccnNo,
        //   mmf_dfra_dl_no   : sMmfDfraDlNo,
        //   //todo.. 확인중 
        //   // sup_id           : this.lv_basInfo.gv_adm_eno, // 승인자
        //   // aprv_sno         : this.lv_basInfo.gv_adm_seq_no, // 승인번호	
        // })
        
        // 확인중 todo..
        // console.log("들어가기전 = "+this.ds_rp200500_i_00vo.saveXML());

        //------------------fn_prepareConfirm end ----------------------------------

        //todo.. 확인중 
        // trace(this.ds_rp200500_i_00vo.saveXML());
        console.log('211 po 전 111 @@@@@@@@@@@@@@@@@@@@@@@@@@', this.ds_bridge_req[0]);
        
        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570211_S'
        this.eaiCommObj.params = {
          acct_seq_no      : String(sBuyAccnNo).substr(10),
          bfcer_cstgr_no   : String(sBuyAccnNo).substr(0, 10),
          buy_accn_no      : this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
          buy_apl_amt      : (this.draw_gb == "0") ? this.req_amt.value : this.paym_amt.value,
          dfra_accn_no     : this.ds_acct_no[0].bfcer_apamt_accn_no,
          dfra_dl_no       : '',//this.ds_bridge_req[0].dfra_dl_no, //출금거래번호 
          mmf_dfra_accn_no : sMmfDfraAccnNo,
          mmf_dfra_dl_no   : sMmfDfraDlNo,//FSCommUtil.gfn_isNull(sMmfDfraDlNo) ? '' : Number(sMmfDfraDlNo) - 70,
          proc_gb          : "I",
          aprv_sno         : this.gv_adm_seq_no, 
          sup_id           : this.gv_adm_eno,
          tr_code          : 'RP2005',
          media            : 'I',
          bp_search_auth_yn: 'N',
          tel_use_yn       : '',
          proc_gb          : 'I',
          
        }

        console.log('브릿지 PO param -========', this.eaiCommObj.params);

        FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack_bridge)

        console.log('211 po 후 @@@@@@@@@@@@@@@@@@ ')

        // var sSvcID        = "insertRP200500_Service";
        // var sInDatasets   = "rp200500_i_00vo=ds_rp200500_i_00vo";
        // var sOutDatasets  = "ds_rp200500_o_out=rp200500_o_00vo gds_admission_info=admission_info";
        // var sCallbackFunc = "fn_callBack";
      } else if (sDrawGb == "1") {
      
          //MMF인경우 계좌이체를 호출한다.
        let t_popupObj = {
          content: 'MMF당일출금을 하시겠습니까?',
          cancel: true,
          confirm: true,
          confirmFunc: this.fn_insert_mmf_redem,
          }
        this.fn_AlertPopup(0, t_popupObj)

      }
    },
     /**************************************************************************************************************
    * Function명 : fn_insert_mmf_redem
    * 설명       : mmf 계좌이체 신청
    ***************************************************************************************************************/
    fn_insert_mmf_redem(){
      console.log("MMF계좌 입력값 처리"  );

      console.log("MMF계좌 입력값 처리1111"  );  	
      var sAcctNo = this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue;
      var objDs = this.ds_acct_no;
      var nRow  = this.lv_ed_acct_gb.value;
      
      var sDepoAcctNo = objDs[nRow].bfcer_apamt_accn_no;	

      console.log("입금예수금계좌 " + sDepoAcctNo );
      
      this.ds_mmf_redem_req.push({
        tr_code             : "RP2015", //거래코드
        proc_tr_cd          : "RP2005", //거래코드 
        media               : "I",
        tel_use_yn          : "",
        ars_pswn_yn         : "",
        paym_cust_no        : String(sAcctNo).substr(0, 7),     // 출금고객번호
        paym_bfcer_cstgr_no : String(sAcctNo).substr(7, 3),     // 출금그룹일련번호
        paym_acct_seq_no    : String(sAcctNo).substr(10, 3),    // 출금계좌일련번호
        secure_passv        : this.passwd2.value,               // 비밀번호
        redem_gb            : this.redem_gb.value,              // 환매구분
        part_paym_possamt   : (this.redem_gb.value == "11") ? this.paym_possamt.value : "" , // 출금가능금액	
        paym_amt            : this.paym_amt.value,              // 금액(좌수)
        paym_cal_issue_yn   : "N",                              // 지급계산서발급
        recp_cust_no        : String(sDepoAcctNo).substr(0, 7), // 입금고객번호
        recp_bfcer_cstgr_no : String(sDepoAcctNo).substr(7, 3), // 입금그룹일련번호
        recp_acct_seq_no    : String(sDepoAcctNo).substr(10, 3),// 입금계좌일련번호
        buy_plan_acct_no    : this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, // 입금계좌일련번호
        ENCALGO             : 'SEED',
        aprv_sno            : this.gv_adm_seq_no, 
        sup_id              : this.gv_adm_eno,
        bp_search_auth_yn   : 'N'

      });
      //todo.. 확인중 
      //console.log(this.ds_mmf_redem_req.saveXML());
            
      //서버에 호출 할 정보를 셋팅해준다.
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570227_S'
      this.eaiCommObj.commHeaderVO.reqCvrPblCd = ''
      this.eaiCommObj.params = this.ds_mmf_redem_req[0]


      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_mmf_redem)
      // var sSvcID        = "insertRP201500_Service";
    },
    /**************************************************************************************************************
    * Function명 : fn_callBack_bridge
    * 설명       : 브릿지 - CONFIRM 확인버튼 CALLBACK
    ***************************************************************************************************************/
    fn_callBack_bridge(response){
      console.log('fn_callBack_bridge', response)
      // 2021.08.05 김재연 ASR210700115_수익_추가매수에 대한 적합성 원칙 적용 로직 반영 - Begin. 
      let t_popupObj = {}
      // t_popupObj.content = FSMessageUtil.commMessage([])[response.data.error_msg]
      // this.fn_AlertPopup(0, t_popupObj)
      
      if( response.data.error_msg == '809900' || response.data.error_msg == '809905' ) {
        
        if ( response.data.tm_bes_dl_yn == "Y" ) {
            this.getStore('toast').dispatch('ADD', 'LT(Late-Trading)거래입니다. 고객님께 안내 바랍니다.')
          }

        // 책임자 승인
        //if( FSCommUtil.gfn_isNull(this.gv_adm_seq_no) ){
        console.log("버튼비활성화");
        this.btn_confirm = true;

        //서식/장표 report 출력
        this.ds_report = response.data

        var cust_no = (this.lv_ed_accn_no.value).substr(0, 7)
        let t_msg = FSMessageUtil.commMessage([])[response.data.error_msg]
        let t_popupObj = {
          confirm: true,
          content: [t_msg],
          confirmFunc: this.fn_ReportPrint,
          confirmData: {
            bfcer_cust_no : cust_no
          },
        }
        this.fn_AlertPopup(9, t_popupObj)

        
         //브릿지 계좌  
        if ( this.draw_gb == "0"){

          var sDrawGb = this.draw_gb;
          this.depo_req_amt.value = (sDrawGb == "0")?this.req_amt.value:this.paym_amt.value;
          
          //매입계좌번호
          this.depo_acct_no_MMF.value = this.fn_acctNoDashFormat(this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue);		    

        }

      } 
      
      if ( response.data.error_msg == '050013' && this.gv_adm_seq_no == 0 && this.gv_adm_eno == '' ) {
        
        console.log("책임자 승인");
        // 책임자 승인 요청
        // todo.. 확인필요 공통함수 
        // this.gfn_requestAdmission(this, this.fn_confirm);
        let paramData = {
          adm_proc_gb    : response.data.adm_proc_gb   , // 승인처리구분 : 0 - 승인요청 1 - 승인응답
          trt_ymd        : response.data.trt_ymd       , // 처리일
          bfcer_fof_cd   : response.data.bfcer_fof_cd  , // 처리지점
          bfcer_fof_nm   : response.data.bfcer_fof_nm  , // 처리지점명
          aprv_req_sno   : response.data.aprv_req_sno  , // 승인일련번호
          req_tr_code    : response.data.req_tr_code   , // 처리요청 tr_code
          req_tr_code_nm : response.data.req_tr_code_nm, // 처리화면명
          rqstr_eno      : response.data.rqstr_eno     , // 요청사원번호
          rqstr_eno_nm   : response.data.rqstr_eno_nm  , // 요청사원명
          message        : response.data.message       , // 메시지
          fileSeq        : response.data.fileSeq       , // 캡쳐 파일일련번호
          orgFileNm      : response.data.orgFileNm     , // 캡쳐 원본파일명
          fileChk        : response.data.fileChk       , // 캡쳐 파일체크
          gv_adm_seq_no  : this.gv_adm_seq_no , // 승인일련번호
          gv_adm_eno     : this.gv_adm_eno    , // 승인사원번호
          adm_eno        : response.data.adm_eno       , // 승인자 리스트
        }

        console.log("책임자 승인 paramData : ", paramData)

        this.fn_OpenPopup('MSPFS320P', paramData);
        // this.fn_AlertPopup(0, {content:FSMessageUtil.commMessage([],response.data.error_msg)[response.data.error_msg.substring(0,6)]})
        return


      } 
      
      if ( FSCommUtil.gfn_trim(response.data.error_msg) != '' && response.data.error_msg != '809905' ) {
        
        let t_popupObj = {}

        if(FSCommUtil.gfn_length(response.data.error_msg) >  6){
          t_popupObj = {content: response.data.error_msg}
        }else{
          t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        }
        this.fn_AlertPopup(0, t_popupObj)
      }
      
      if( response.data.aprv_req_sno != 0  && this.gv_adm_seq_no == 0 && this.gv_adm_eno == '' ) {
          let adm_eno_list = []
          let adm_eno = {
            staf_eno       : response.data.staf_eno      ,	  // 책임자 사원번호
            staf_nm        : response.data.staf_nm       ,	  // 책임자 명
            fof_nm         : response.data.fof_nm        ,	  // 책임자 지점명
            auth_grp_cd_nm : response.data.auth_grp_cd_nm,    // 책임자 그룹명
            user_ip        : response.data.user_ip       ,	  // 책임자 IP
          }
          if ( !FSCommUtil.gfn_isNull(response.data.staf_eno) ) {
            adm_eno_list.push(adm_eno)
          }

          let paramData = {
            adm_proc_gb    : response.data.adm_proc_gb   , // 승인처리구분 : 0 - 승인요청 1 - 승인응답
            trt_ymd        : response.data.trt_ymd       , // 처리일
            bfcer_fof_cd   : response.data.bfcer_fof_cd  , // 처리지점
            bfcer_fof_nm   : response.data.bfcer_fof_nm  , // 처리지점명
            aprv_req_sno   : response.data.aprv_req_sno  , // 승인일련번호
            req_tr_code    : response.data.req_tr_code   , // 처리요청 tr_code
            req_tr_code_nm : response.data.req_tr_code_nm, // 처리화면명
            rqstr_eno      : response.data.rqstr_eno     , // 요청사원번호
            rqstr_eno_nm   : response.data.rqstr_eno_nm  , // 요청사원명
            message        : response.data.message       , // 메시지
            fileSeq        : response.data.fileSeq       , // 캡쳐 파일일련번호
            orgFileNm      : response.data.orgFileNm     , // 캡쳐 원본파일명
            fileChk        : response.data.fileChk       , // 캡쳐 파일체크
            gv_adm_seq_no  : this.gv_adm_seq_no , // 승인일련번호
          gv_adm_eno       : this.gv_adm_eno    , // 승인사원번호
            adm_eno_list   : adm_eno_list    		  // 승인자 리스트
          }

          console.log("책임자 승인 paramData : ", paramData)

          this.pPopup320Obj = paramData
          this.$refs.popup320.fn_Open()
				}
     
    },

    /***************************************************************************************
     * Function명 : fn_call_back_mmf_redem
     * 설명       : MMF - 계좌 확인 버튼 클릭 콜백 (MMF당일 출금 결과 확인 팝업)
    *****************************************************************************************/
    fn_call_back_mmf_redem(response){
      console.log('fn_call_back_mmf_redem 호출', response);

      if( response.data.error_msg == "809900" || response.data.error_msg == "809905" ){
        
        this.ds_mmf_redem = response.data;

        var nAcctRow  = this.lv_ed_acct_gb.value;
        var objAcctDs = this.ds_acct_no;

        // 팝업화면으로 보낼 파라미터.
        let oArg           = {
          redem_acct_no     : '',
          depo_acct_no      : '',
          acct_no           : '',
          req_amt           : '',
          part_paym_possamt : '',
          return_msg        : '',
        };            

        oArg.redem_acct_no = this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue;
        oArg.depo_acct_no  = objAcctDs[nAcctRow].bfcer_apamt_accn_no;
        oArg.acct_no       = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;//parent.Div_acct_no.Cb_acct_no.value;
        oArg.return_msg    = response.data.error_msg;

        if ( this.redem_gb.value == '13' ){
          //정액인경우는 금액(좌수)
          oArg.req_amt           = this.paym_amt.value;//parent.Me_part_paym_possamt;
          oArg.part_paym_possamt = this.paym_amt.value;//parent.Me_part_paym_possamt.Value;			    
        }else {
          //전액및 좌수는 출금신청금액(좌수)
          oArg.req_amt           = this.part_paym_possamt.value;//parent.Me_part_paym_possamt;
          oArg.part_paym_possamt = this.part_paym_possamt.value;//parent.Me_part_paym_possamt.Value;			    
        }   
        
        let lv_vm = this
        let t_popupObj = {
          confirm : true,
          cancel : false, 
          content: FSMessageUtil.commMessage([])[response.data.error_msg],
          confirmFunc : function(){
              //MMF당일출금확인 팝업 오픈 
              lv_vm.pPopup314Obj = oArg;
              lv_vm.fn_OpenPopup('MSPFS314P', oArg);
            }
          }
        this.fn_AlertPopup(0, t_popupObj)
        

      }else{
        
        let t_popupObj = {content: FSMessageUtil.commMessage([])[response.data.error_msg]}
        this.fn_AlertPopup(0, t_popupObj)

      }
      
    },
    /**************************************************************************************************************
    * Function명 : fn_validateConfirmAcct
    * 설명       : 브릿지 - 계좌 확인 버튼 클릭 시 
    ***************************************************************************************************************/
    fn_validateConfirmAcct(){
      console.log("fn_validateConfirmAcct....");
      
      var sAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue  // 계좌번호

      console.log("확인버튼 브릿지1111")

      var nDepoReamt = this.depo_reamt.value
      if (FSCommUtil.gfn_isNull(nDepoReamt)){
        nDepoReamt = "";
      } 
      nDepoReamt = Number(nDepoReamt)

      //신청금액
      var nReqAmt = this.req_amt.value
      
      if(FSCommUtil.gfn_isNull(nReqAmt)){
        nReqAmt = ""
      } 
      nReqAmt = Number(nReqAmt)
      console.log("확인버튼 브릿지22222")
        
      if (FSCommUtil.gfn_isNull(sAcctNo) || String(sAcctNo).length != 13) {
        let t_popupObj = {content: "계좌번호 입력오류입니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.$refs['ed_accn_no'].focus()  
        return false;
      }
      
      // -------------------------------- tobe 적합도체크 기능 제외로 체크하지 않음 ------------------------------------
      // console.log("펀드적합성 : " + this.fnd_adj_stat_cd);
        
        // 투자설문 없음.    
      // if(this.fnd_adj_stat_cd == "X"){
      //   let t_popupObj = {
      //     cancel: false,
      //     confirm: true,
      //     confirmFunc: this.fn_goInvest_servey(),
      //     confirmData: FSCommUtil.gfn_substr(this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, 0, 7),
      //     content: "투자성향분석이 필요합니다.\n평가를 진행하시겠습니까?",
      //   }
      //   console.log('투자설문param 고객번호 확인', t_popupObj.confirmData);
      //   this.fn_AlertPopup(0, t_popupObj)
      // }    
        
      
      // 적합도체크 : 미체크    
      // if ( this.fnd_adj_stat_cd == "N" ) {
      //   FSCommUtil.gfn_validate(this, "적합성체크를 실행하시기 바랍니다.")
      //   this.$refs['btn_inq_fnd_adj'].focus()
      //   return false;
      // }
      
      // 2023.06 적합성 체크 기능 삭제 요청 
      // 투자설문2년경과 또는 부적합.	
      // if ( this.fnd_adj_stat_cd == "E" || this.fnd_adj_stat_cd == "F" ) {
      
      //   var ivt_prpns_2y_exp_yn; // 투자성향2년경과여부
      //   var ivt_prpns_stnd_ymd; // 최종투자설문작성일	
        
      //   if( FSCommUtil.gfn_length(this.ds_ivt_fnd_adj_info)> 0 ) {
      //     // FROM 계좌정보
      //     ivt_prpns_2y_exp_yn = this.ds_ivt_fnd_adj_info[0].ivt_prpns_2y_exp_yn; // 투자성향2년경과여부
      //     ivt_prpns_stnd_ymd =  this.ds_ivt_fnd_adj_info[0].ivt_prpns_stnd_ymd; 	// 최종투자성향작성일	
      //   }else{
      //     // FROM 고객정보
      //     ivt_prpns_2y_exp_yn = this.div_custInfo.ds_cust_info[0].ivt_prpns_2y_exp_yn; 	// 투자성향2년경과여부
      //     ivt_prpns_stnd_ymd 	= this.div_custInfo.ds_cust_info[0].expd_stnd_ymd; 			// 최종투자설문작성일	
      //   }
      
      //   let t_popupObj = {
      //     cancel: false,
      //     confirm: true,
      //     confirmFunc: this.fn_goInvest_servey(),
      //     confirmData: FSCommUtil.gfn_substr(this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, 0, 7),
      //     content: "투자성향결과(" + ivt_prpns_stnd_ymd + ")가 2년초과 or 부적합상품입니다.\n투자성향진단 재평가를 진행하시겠습니까?",
      //   }
      //   console.log('투자설문param 고객번호 확인2222', t_popupObj.confirmData);
      //   this.fn_AlertPopup(0, t_popupObj)
      //   return false;
      // }
      
      // 2021.08.05 김재연 ASR210700115_수익_추가매수에 대한 적합성 원칙 적용 로직 반영 - End.
      console.log("신청금액nReqAmt : " + nReqAmt);
      if (FSCommUtil.gfn_isNull(nReqAmt) || nReqAmt <= 0 ) {
        let t_popupObj = {content: "신청금액 입력오류입니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.$refs['req_amt'].focus()
        return false;
      }
      console.log("예수금금액nDepoReamt : " + nDepoReamt);
      if (nReqAmt > nDepoReamt) {
        let t_popupObj = {content: "신청금액은 예수금 범위내에서만 처리 가능합니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.$refs['req_amt'].focus()
        return false;
      }
      return true;
   },
    
    /***************************************************************************************
    * Function명 : fn_validateConfirmRedem
    * 설명       : 확인버튼 MMF계좌
    *****************************************************************************************/
    fn_validateConfirmRedem(){
      var nAcctRow         = this.lv_ed_acct_gb.value;
      var objAcctDs        = this.ds_acct_no;
      var sAcctNo          = this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue;
      var sPasswd          = this.passwd2.value
      var sRedemGb         = this.redem_gb.value;
      var nPaymAmt         = this.paym_amt.value;
      var nPartPaymPossAmt = this.part_paym_possamt.value;
      var sDepoAcctNo      = objAcctDs[nAcctRow].bfcer_apamt_accn_no;
      var nRedemAcctRow    = this.lv_ed_acct_gb2.value;
      var objRedemAcctDs   = this.ds_acct_no2;
      var sRedemPbkTaxTpGb = objRedemAcctDs[nRedemAcctRow].pbk_tax_tp_gb;
      var sPbkTaxTpGb      = objAcctDs[nAcctRow].pbk_tax_tp_gb;

      if (FSCommUtil.gfn_isNull(nPaymAmt)){
        nPaymAmt = "";
      } 

      if(FSCommUtil.gfn_isNull(nPartPaymPossAmt)){
        nPartPaymPossAmt = "";
      } 
      console.log("확인버튼 MMF계좌44444");

      nPaymAmt = Number(nPaymAmt);
      nPartPaymPossAmt = Number(nPartPaymPossAmt);

      console.log("확인버튼 MMF계좌55555");
        
      if (FSCommUtil.gfn_isNull(sAcctNo) || String(sAcctNo).length != 13) {
        FSCommUtil.gfn_validate(this, "계좌번호 입력오류입니다.")
        this.$refs['ed_accn_no2'].focus()
        return false;
      }
      console.log("확인버튼 MMF계좌5555511111");

      if(this.isMobile && this.isMtrans){
        if (FSCommUtil.gfn_isNull(this.lv_masked_val2) || FSCommUtil.gfn_length(this.lv_masked_val2) < 4 ) {
          FSCommUtil.gfn_validate(this, "비밀번호 입력오류입니다.")
          this.$refs['passwd2'].focus()
          return false;
        }
      }else{
        if (FSCommUtil.gfn_isNull(sPasswd) || FSCommUtil.gfn_length(sPasswd) < 4 ) {
          FSCommUtil.gfn_validate(this, "비밀번호 입력오류입니다.")
          this.$refs['passwd2'].focus()
          return false;
        }
      }

      
      console.log("확인버튼 MMF계좌555552222");
      console.log("환매구분 : " + sRedemGb );

      if(FSCommUtil.gfn_isNull(sRedemGb) || String(sRedemGb).length != 2) {
        FSCommUtil.gfn_validate(this, "환매구분 입력오류입니다.")
        this.$refs['redem_gb'].focus()
        return false;
      } else {
        console.log("확인버튼 MMF계좌5555533333");
        switch (sRedemGb) {
          case 11 : 
            break;
          case 14 : 
            break;
          default:
            if (FSCommUtil.gfn_isNull(nPaymAmt) || nPaymAmt == 0) {
              FSCommUtil.gfn_validate(this, "금액(좌수) 입력오류입니다.")
              this.$refs['paym_amt'].focus()
              return false; 
            }
          break;
        }
      }
      console.log("확인버튼 MMF계좌6666");
      console.log("nPartPaymPossAmt : " + nPartPaymPossAmt);    
        
      if( nPartPaymPossAmt == null  || nPartPaymPossAmt == 0) {
        FSCommUtil.gfn_validate(this, "금액(좌수) 확인버튼을 눌러주십시오.")
        return false;
      }
      if (FSCommUtil.gfn_isNull(sDepoAcctNo) || String(sDepoAcctNo).length != 13) {
        FSCommUtil.gfn_validate(this, "입금이체계좌번호 입력오류입니다.")
        this.$refs['ed_acct_gb'].focus()
        return false;
      }
      
      if (this.lv_acct_list2[Number(this.lv_ed_acct_gb2.value)].hiddenValue == this.depo_acct_no.value) {
        FSCommUtil.gfn_validate(this, "이체할 계좌번호가 같습니다.")
        return false;
      }
      if (sRedemPbkTaxTpGb == "13") {
        FSCommUtil.gfn_validate(this, "신연금 통장은 계좌이체 처리를 할 수 없습니다.")
        return false;
      }
      if (sPbkTaxTpGb == "13") {
        FSCommUtil.gfn_validate(this, "신연금 통장은 계좌이체 처리를 할 수 없습니다.")
        return false;
      }

      return true;
    },
    
    /***************************************************************************************
    * Function명 : fn_req_amt_onchanged
    * 설명       : 신청금액 변경 시
    *****************************************************************************************/
    fn_req_amt_onchanged(){
      this.depo_req_amt.value = this.req_amt.value;
    },

    /***************************************************************************************
    * Function명 : fn_cbo_redem_gb_onitemchanged
    * 설명       : 환매구분 선택시 
    *****************************************************************************************/
    fn_cbo_redem_gb_onitemchanged(){
      // 입력구분에 따른 인출가능금액조회
      if (this.redem_gb.value == '11') {//전액환매
        this.paym_amt.disabled = true;
        this.paym_amt.value = "";
        this.btn_redem_chk = true;	
            
        //자동호출 
        this.fn_getRedemPossamt("S0");
        
      } else {
        this.paym_amt.value = "";
        this.part_paym_possamt.value = "";
        this.paym_amt.disabled = false;
        this.btn_redem_chk = false;
      }
        this.select_yn = "N";
    },

    /***************************************************************************************
    * Function명 : fn_paym_amt_onchar
    * 설명       : MMF금액(좌수) 변경시  
    *****************************************************************************************/
    fn_paym_amt_onchar(){
      if(paym_amt.value > 0){
        this.btn_redem_chk = false
      }else{
        this.btn_redem_chk = true
      }
    },

    /***************************************************************************************
    * Function명 : fn_btn_redem_chk_onclick
    * 설명       : 출금가능금액 확인 버튼   
    *****************************************************************************************/
    fn_btn_redem_chk_onclick(){
      if (this.redem_gb.value == '12' ){//좌수환매
        if (Number(this.paym_possqty.value) < Number(this.paym_amt.value)) {
          FSCommUtil.gfn_validate(this, '출금가능좌수를 초과하였습니다.')
          this.paym_amt.value = "";
          this.part_paym_possamt.value = "";
          return;	
        }
      } else if (this.redem_gb.value == '13' ){//정액환매
        if (Number(this.paym_possamt.value) < Number(this.paym_amt.value)) {
          FSCommUtil.gfn_validate(this, '출금가능금액을 초과하였습니다.')
          this.paym_amt.value = "";  //금액(좌수)
          this.part_paym_possamt.value = "";  //출금신청금액(좌수)
          return;
        }
      } 

      // 입력구분에 따른 인출가능금액조회
      this.fn_getRedemPossamt("S0");
    },

    /**************************************************************************************************************
    * Function명 : fn_goInvest_servey
    * 설명       : 투자성향진단 메뉴 이동 
    ***************************************************************************************************************/
    fn_goInvest_servey(cust_no){
      this.$router.push({
        name: 'MSPFS110M', 
        params: {
          fnd_cust_no: cust_no
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_agntSelect
     * 설명       : 대리인 조회 
     ******************************************************************************/
    fn_agntSelect(){
      console.log('대리인 조회 호출')
      if(this.agnt_chk_yn.value == 'N'){
        return;
      }
      var sAcctNo = "";

      /* 계좌번호 조회 여부 및 선택  확인 */
      console.log('대리인 조회 - 계좌번호 value ', this.lv_ed_accn_no.value );

      if(FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)) {
        let t_popupObj = {content: "계좌가 입력되지 않았습니다."}
        this.fn_AlertPopup(0, t_popupObj)
        this.agnt_chk_yn.value = 'N';
        return;
      }
      

      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no : FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7),
        tr_code : 'RP200500',

        // 추가정보
        menu_id : 'MSPFS309M',
        btn_id: 'agnt_chk_yn',
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 313업무대리인조회 / 1:  314 MMF당일출금확인+매입신청
     ******************************************************************************/
    fn_OpenPopup(type, pData) {
      switch (type) {
        case 'MSPFS313P':
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break          
        case 'MSPFS314P':
          console.log('314P호출', this.pPopup314Obj);
          this.pPopup314Obj = pData
          this.$refs.popup314.openModal()
          break
        case 'MSPFS320P':
          console.log('320P호출', this.pPopup320Obj)
          this.pPopup320Obj = pData
          this.$refs.popup320.fn_Open()
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack313
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 313업무대리인조회 
     ******************************************************************************/
    fn_Popup_CallBack313(type, pData, subId) {
      console.log('313 call back ', pData)

    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack314
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) :  1:  314 MMF당일출금확인+매입신청
     ******************************************************************************/
    fn_Popup_CallBack314(type, pData, subId) {
      console.log('314 call back ', type)
      console.log('314 call back ', pData)
      console.log('314 call back ', subId)

      var nAcctRow  = this.lv_ed_acct_gb.value;
      var objAcctDs = this.ds_acct_no;

      if (pData == "Y") {
        //예수금잔고에 출금액 더해줘
        var nAcctDepoPaymPossAmt = objAcctDs[nAcctRow].depo_paym_poss_amt;
        if(FSCommUtil.gfn_isNull(nAcctDepoPaymPossAmt)){
          nAcctDepoPaymPossAmt = 0;
        }
        nAcctDepoPaymPossAmt = Number(nAcctDepoPaymPossAmt);
        console.log("예수금총잔액"+ nAcctDepoPaymPossAmt );
        
        var nPartPaymPossamt = 0;
        if( this.redem_gb.value == '13' ){
          nPartPaymPossamt = this.paym_amt.value;
        } else{
          nPartPaymPossamt = this.part_paym_possamt.value;
        }
            
        if (FSCommUtil.gfn_isNull(nPartPaymPossamt)){
          nPartPaymPossamt = 0;
        }
        nPartPaymPossamt = Number(nPartPaymPossamt);
        console.log("MMF신청액"+ nPartPaymPossamt );

        var nAcctDepoAcctNo = objAcctDs[nAcctRow].bfcer_apamt_accn_no;
        if (FSCommUtil.gfn_isNull(nAcctDepoAcctNo)){
          nAcctDepoAcctNo = 0;
        }
        nAcctDepoAcctNo = Number(nAcctDepoAcctNo);

        this.depo_acct_no.value = nAcctDepoAcctNo;

        this.recp_acct_no.value = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue;

        //브릿지계좌로 처리
        this.draw_gb = "0";
        //예수금잔액(MMF신청금액 더해준값)
        this.depo_reamt.value = nAcctDepoPaymPossAmt + nPartPaymPossamt;				
        //신청금액
        this.req_amt.value = nPartPaymPossamt;
        
        this.fn_confirm();
      }	

    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack320
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 320업무대리인조회 
     ******************************************************************************/
    fn_Popup_CallBack320(type, pData, subId) {
      console.log('320 call back ', type, pData, subId)
      if(type.successYN == "Y"){
        this.gv_adm_seq_no = type.gv_adm_seq_no
        this.gv_adm_eno = type.gv_adm_eno
        this.fn_confirm();
      }else{
        return
      }
      
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint () {
      console.log('fn_ReportPrint....', this.ds_report)

      let formList = []
      let FS000014 = {formId: 'FS000014'}
      // let FS000000 = {formId: 'FS000000'}

      // let tmpRow = this.lv_acct_list.filter(v=>v.value === this.lv_ed_acct_gb.value)[0]
      // let tmpFundNm = tmpRow.text.split(' ')[1]
      // if(tmpFundNm) {
      //   FS000000.mappingData = {fund_nm: tmpFundNm}
      // }
      // formList.push(FS000000)

      let searchData = {
        bfcer_cust_no    : this.ds_report.bfcer_cust_no,
        frm_issnc_ymd    : this.ds_report.frm_issnc_ymd,
        issnc_hstr_sno   : this.ds_report.issnc_hstr_sno,
        bfcer_accn_no    : this.lv_ed_accn_no.value,
        bfcer_busn_trt_id: 'RP2005',
        rprt_frm_no      : '0027',
        // acct_no          : this.draw_gb == 1 ? this.lv_ed_accn_no.value : '',
      }
      
      FS000014.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportCO070500_Info',
      }
      formList.push(FS000014)

      console.log('formList param === ', searchData)
      FSInfoUtil.commReportInfo(this, formList)

    },

    /******************************************************************************
     * Function명 : fn_GoAgentRegist
     * 설명       : 업무대리인등록/변경
     ******************************************************************************/
    fn_GoAgentRegist() {
      this.$router.push({
        name: 'MSPFS302M', 
        params: this.lv_ed_accn_no.value.substr(0, 7)//{cob_rname_gb: this.lv_cob_rname_gb.value, ed_rname_no: [this.lv_ed_rname_no.value1, this.lv_ed_rname_no.value2]}
      })
    },

    /******************************************************************************
     * Function명 : fn_acctSearch
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_acctSearch(event, flag, val){
      this.lv_ed_accn_no.error = false; 
      console.log('fn_acctSearch=====', event);
      if(event.key == "Backspace" && this.draw_gb == "0"){
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''
        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()
      }

      let t_accn_no = ''


      if(val == 2){ //MMF
        

        t_accn_no = this.lv_ed_accn_no2.value.replace(/[^0-9]/g, '')

        if(FSCommUtil.gfn_length(t_accn_no) < 10){
          return;
        }


        let t_data = {bfcer_cstgr_no : t_accn_no,
                      input_check : '81',        // 0.전체, 1.예수금, 2.예수금제외, 3.MMF계좌, 4.MMF예수금제외, 5.MMF+예수금      
                      bfcer_accn_stat_cd : 'Y', // 계좌상태 플래그 설정
                      int_tr_yn : 'N',          // 인터넷 거래여부
                      bridge_aply_cd : ''      // 브릿지계좌 적용여부
                      }
        FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult2)

      }else{  //브릿지

        t_accn_no = this.lv_ed_accn_no.value.replace(/[^0-9]/g, '')
        if(FSCommUtil.gfn_length(t_accn_no) < 10){
          return;
        }
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

        let t_data = {bfcer_cstgr_no : t_accn_no,
                      input_check : 'C',        // 0.전체, 1.예수금, 2.예수금제외, 3.MMF계좌, 4.MMF예수금제외, 5.MMF+예수금      
                      bfcer_accn_stat_cd : 'Y', // 계좌상태 플래그 설정
                      int_tr_yn : 'N',          // 인터넷 거래여부
                      bridge_aply_cd : ''      // 브릿지계좌 적용여부
                      }
        FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
      }
    },

    fn_AccnNoListResult(pResultData) {
      console.log('fn_AccnNoListResult.....', pResultData)

      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      this.lv_ed_acct_gb.disabled = true

      if( t_data.length > 0 ) {
        if( FSCommUtil.gfn_isNull( t_data[0].fnd_cust_nm ) ){
          let t_popupObj = {content: "해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다."}
          this.fn_AlertPopup(0, t_popupObj)
	        return;
	      }
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
        })
        
        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        this.lv_ed_acct_gb.disabled = false

        // 거래내역조회 결과 초기화
        this.lv_trd_list = []

        // 계좌정보 저장 
        this.ds_acct_no = t_data; 

        // 고객정보조회
        this.fn_CustInfo()

      } else {
        this.lv_acct_list = []
        // 불필요한 팝업이므로 주석 처리
        let t_popupObj = {content: "검색할 계좌가 없습니다."}
        this.fn_AlertPopup(0, t_popupObj)
      }
      
    },

    fn_AccnNoListResult2(pResultData) {
      console.log('MMF계좌조회 ========>', pResultData);
      
      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list2 = []
      this.lv_ed_acct_gb2.disabled = true

      if( t_data.length > 0 ) {
        if( FSCommUtil.gfn_isNull( t_data[0].fnd_cust_nm ) ){
          let t_popupObj = {content: "해당 고객은 당사 거래 종료로 인한 개인정보 접근제한 고객입니다."}
          this.fn_AlertPopup(0, t_popupObj)
	        return;
	      }
        this.lv_ed_cust_nm2.value = t_data[0].fnd_cust_nm;

        t_data.forEach((item, idx) => {
          this.lv_acct_list2.push({value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no})
        })
        
        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb2.value = 0
        this.lv_ed_acct_gb2.disabled = false

        // 계좌정보 저장 
        this.ds_acct_no2 = t_data;

        // 거래내역조회 결과 초기화
        this.lv_trd_list2 = []
      } else {
        this.lv_acct_list2 = []
        // 불필요한 팝업이므로 주석처리
        let t_popupObj = {content: "검색할 계좌가 없습니다."}
        this.fn_AlertPopup(0, t_popupObj)
      }
    },
    
    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....')

      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()

      this.fn_Acct_no_call();
    },

    fn_SetEdCustName(val) {
      // 고객명
      //this.lv_ed_cust_nm.value = val
    },

    /******************************************************************************
     * Function명 : fn_acctNoDashFormat
     * 설명       : 계좌번호 처리  
     ******************************************************************************/
    fn_acctNoDashFormat(acctNo){
      acctNo = FSCommUtil.gfn_substr(acctNo, 0, 7) + "-" + FSCommUtil.gfn_substr(acctNo, 7, 3) + "-" + FSCommUtil.gfn_substr(acctNo, 10, 3) 
      return acctNo;
    },
    
    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        {'cId':'231', 'dayChkYn':'N'},
        {'cId':'322', 'dayChkYn':'N'},
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      let t_codeList322 = []
      pResult.code322.forEach((item) => {
        if( item.value == '11' || item.value == '12' || item.value == '13') {
          t_codeList322.push(item)
        }
      })

      this.lv_commCode.code322 = t_codeList322;
    },

    /******************************************************************************
      * Function명 : fn_AlertPopup
      * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      if( !FSCommUtil.gfn_isNull(pPopupObj) ) {

        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },
    
    
  }
};
</script>
<style scoped>
</style>
/*
 * 업무구분: 영업
 * 화 면 명: MSPFS314P
 * 화면설명: MMF당일출금확인_매입신청_P RP200501P.xfdl
 * 작 성 일: 2023.01.16
 * 작 성 자: 이지수
 */
<template>
  <mo-modal class="fts-modal medium" ref="modal" title="MMF당일출금확인+매입신청">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type col3070 pr20"> <!-- class 추가 "pr20" 2023-04-11 jy -->            
            <tbody>
              <tr>
                <th>
                  <span> 출금계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long"  mask="#######-###-###" v-model="redem_acct_no" disabled /> <!-- 현 팝업상의 모든 readonly를 disabled로 변경 2023-04-10 jy -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 입금계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long"  v-model="depo_acct_no" mask="#######-###-###" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 이체금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="part_paym_possamt" disabled />
                    <span>원</span> <!-- "원" 텍스트 추가 2023-04-10 jy -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 이체결과 </span>
                </th>
                <td>
                  {{redem_rslt_msg}} <!-- 정상 처리 되었습니다. -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="mt16"><span class="colorR">※ 아래 정보로 매입신청 진행하시겠습니까?</span></p>
        <div class="wrap-table mt6">
          <table class="table col-type col3070 pr20"> <!-- class 추가 "pr20" 2023-04-11 jy -->
            <!-- <colgroup>
              <col width="30%">
              <col width="70%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 매입신청계좌 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="#######-###-###" v-model="stc_recp_acct_no" disabled />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 매입신청금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="mae_req_amt_popup" disabled />
                    <span>원</span> <!-- "원" 텍스트 추가 2023-04-10 jy -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="closeModal">아니오</mo-button>
        <mo-button primary size="large" @click="fn_close">예</mo-button>
      </div>
    </template>
  </mo-modal>
  
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS314P",
  screenId: "MSPFS314P",
  components: {},
  props: {
      popupObj: {type:Object, default:null},
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    this.$bizUtil.insSrnLog('MSPFS314P')    
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 대리인 Table
      redem_acct_no: '',
      depo_acct_no: '',
      part_paym_possamt: '',
      stc_redem_rslt_msg : '',
      stc_recp_acct_no: '',
      mae_req_amt_popup: '',

      redem_rslt_msg : '', 
      recp_acct_no : '', 
      req_amt : '',
      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {
      return this.$refs.modal
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    // modal
    fn_onLoad(){
      console.log('fn_onLoad...', this.popupObj);

      if(!FSCommUtil.gfn_isNull(this.popupObj)){
        //MMF당일출금 정보
        console.log("MMF 출금계좌 :"     + this.popupObj.redem_acct_no);    	
        console.log("MMF 입금계좌 :"     + this.popupObj.depo_acct_no); 
        console.log("MMF 출금신청금액 :" + this.popupObj.part_paym_possamt);    	
        console.log("MMF 결과 :"         + this.popupObj.return_msg);  	
        this.redem_acct_no     = this.popupObj.redem_acct_no  //출금계좌
        this.depo_acct_no      = this.popupObj.depo_acct_no    //입금계좌
        this.part_paym_possamt = this.popupObj.part_paym_possamt  //출금신청금액
        this.redem_rslt_msg    = FSMessageUtil.commMessage([])[this.popupObj.return_msg] 
        //매입신청 정보
        this.stc_recp_acct_no  = this.popupObj.acct_no
        this.mae_req_amt_popup = this.popupObj.req_amt
      }
      
    },

    fn_close(){
      console.log('314P fn_close...')
      let lv_returnObj = 'Y'
          
      this.$emit('fs-popup-callback', 14, lv_returnObj)

      this.modal.close();
    },

    openModal(type) {
      this.$nextTick(() => {        
        this.fn_onLoad();
      })
      this.modal.open();
    },

    closeModal(type) {
      this.modal.close();
    },

    
  }
};
</script>
<style scoped>
</style>